$cdn: 'https://assets.unegma.net/starling.red';

/* SCSS HEX */
$yale-blue: #2E446Cff;
$khaki: #C3ADA2ff;
$glaucous: #6C84B0ff;
$coyote: #776654ff;
$tawny: #CA611Cff;

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);
$gradient-right: linear-gradient(90deg, #2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);
$gradient-bottom: linear-gradient(180deg, #2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);
$gradient-left: linear-gradient(270deg, #2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);
$gradient-top-right: linear-gradient(45deg, #2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);
$gradient-bottom-right: linear-gradient(135deg, #2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);
$gradient-top-left: linear-gradient(225deg, #2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);
$gradient-bottom-left: linear-gradient(315deg, #2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);
$gradient-radial: radial-gradient(#2E446Cff, #C3ADA2ff, #6C84B0ff, #776654ff, #CA611Cff);

html, body {
  background: black;
  width: 100vw;
  height: 100%; /* Using 100% because 100vh on mobiles will not include the navbar*/
}

#root {
  height:100%;
  width: 100%;
}

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  //height: 100%;
  width: 100%;
  background-color: $khaki;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

canvas {
  width: 100%;
  height: 100%;
}

.navBar {
  text-align: left;
  color: white;
}

.app-bar {
  background-color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: none !important;
  border: none !important;
}

.MuiPaper-root {
  border-bottom: 1px #4d4d4d solid;
}

.white {
  color: white;
}

.buttons-container {
  position: absolute;
  z-index: 999;
  bottom: 0px;
  right: 0;
  padding: 5px;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
}

/*ipad mini*/
@media screen and (min-width: 768px) {
  .buttons-container {
    bottom: 0;
  }
}

.buttons-container--left {
  left:0;
  right: inherit;
}

.buttons-container--left-helper {
  /*left:5vw;*/
  right: inherit;
}

.buttons-container--mid-right {
  display: flex;
  flex-direction: column;
  right:0;
  bottom: 45%;
  /*right: inherit;*/
}

.helperText {
  color: white;
  display: block;
  margin: 5px 5px;
  text-decoration: underline;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.slide-out-gallery {
  position:absolute;
  right: 0;
  /*width: 100px;*/
  height: 100vh;
  z-index:10;
  overflow:scroll;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(100%); }
}

.slide-out-gallery-container {
  margin-top: 65px; /* height of nav bar */
  flex-direction: column;
  display: flex;
  align-content: center;
  justify-content: center;
}

.slide-out-gallery-image {
  width: 300px;
  height: auto;
}

.closeModalButton {
  color: white;
  cursor: pointer;
  position: absolute !important;
  right: 5px !important;
  top:5px !important;
}


.home-screen-button {
  color: black !important;
  border-color: white !important;
  background-color: white !important;
  font-weight: bold !important;
}

.drawer-link {
  color: white;
  text-decoration: none !important;
}

.main-title {
  cursor: pointer;
  font-size: 28px !important;
  margin-top: 6px !important;
  margin-left: 6px !important;

  a {
    color: white;
    text-decoration: none;
  }
}

.hamburger-button {
  margin-top: 6px !important;
}

.main-title-navbar {
  padding: 8px;
  padding-left: 14px;
}

.info-map {
  min-height: 40vh;
}

.drawer {
  background-color: black;
  color: white;
}

.light-divider {
  background-color: #383838 !important;
}


/*menu*/
.react-pdf__Document {
  display: flex;
  justify-content: center;
}

.react-pdf__Page__canvas {
  max-width:100%;
  max-height: 100%;
  height:100% !important;
  width: 100% !important;
}

.react-pdf__Page__textContent {
  max-width:100%;
  max-height: 100%;
  height:100% !important;
  width: 100% !important;
}


.section {
  position: relative;
  width: 100%;
  min-height: 100vh;

  .canvas-container {
    // prevent interaction with canvas on mobile so can scroll past it (disable on all so works on ipad too)
    pointer-events: none !important;
    @media screen and (min-width: 1080px) {
      pointer-events: auto !important;
    }
  }

  &__one {
    //background-color: white;
    //background-image: url("./images/rain-background2.jpg");
    //background-repeat: no-repeat;
    //background-position: right;
    //background-size: cover;
    position: relative;
    display: flex;
    align-items: center;

    &-description-box {
      display: flex;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
    }

    &-description {
      color: #fff;
      padding: 50px;
    }



    .home-screen-image {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top;
      width: 100%;
      height: 100%;
      background-image: url($cdn + '/blacks-splash-mobile.jpg');

      @media screen and (min-width: 768px) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url($cdn + '/blacks-splash.jpg');
      }

    }

    .home-screen-cover {
      display: flex;
      position: absolute;
      width:100%;
      height:100%;
      //background: rgb(2,0,36);
      //background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
      opacity: 0.9;
      justify-content: center;
      align-items: center;
    }

  }

  &__two {

    //overflow-y: scroll;
    //&::-webkit-scrollbar {
    //  -webkit-appearance: none;
    //  width: 7px;
    //}
    //
    //&::-webkit-scrollbar-thumb {
    //  border-radius: 4px;
    //  background-color: $tawny;
    //  box-shadow: 0 0 1px $tawny;
    //}


    //background-image: url($cdn + '/welcome.jpg');
    //background-image: url($cdn + '/blacks-homepage-section2-mobile.jpg');
    position: relative;
    //background-color: black;
    //background-image: url("./images/rain-hut.jpg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;

    padding: 10vh 0;

    @media screen and (min-width: 768px) {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      //background-image: url($cdn + '/blacks-homepage-section2.jpg');
    }

    display: flex;
    //align-items: top;
    justify-content: center;


    &-container {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .home-page__title-section-two {
      color: white;
      position: relative;
      z-index: 99;

      h1 {
        color: $tawny;
        line-height: 0.9em;
        font-size: 3.4em;
      }

      h2 {
        font-size: 2.3em;
        margin-top:-30px;
      };

      @media screen and (min-width: 768px) {
        position: absolute;
        top: 60vh;
        left: 10vw;

        h1 {
          line-height: 1em;
          font-size: 4em;
        }

        h2 {
          font-size: 1.8em;
          margin-top:-45px;
        }
      }




    }

    .questionnaire-navigation-buttons {
      margin-top: 15px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: space-between;
      justify-content: space-between;
    }


    .questionnaire-back-button {
      color: $tawny !important;
      display: flex;
      align-self: flex-start;
      margin-top: 15px;
    }

    .questionnaire-next-button {
      color: $tawny !important;
      display: flex;
      align-self: flex-end;
      margin-top: 15px;
    }

    .questionnaire-page__title-section-two {
      color: black;
      position: relative;
      z-index: 99;
      max-width: 90vw;
      min-width: 90vw;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h1 {
        color: $tawny;
        //line-height: 0.9em;
        font-size: 2.4em;

        @media screen and (min-width: 768px) {
          font-size: 3.4em;
        }
      }

      h2 {
        color: $tawny;
        font-size: 2.3em;
        //margin-top:-30px;
      };
      h3 {
        color: $tawny;
        //font-size: 2.3em;
        //margin-top:-30px;
      };

      @media screen and (min-width: 768px) {
        //position: absolute;
        //top: 60vh;
        //left: 10vw;

        h1 {
          //line-height: 1em;
          font-size: 4em;
        }

        h2 {
          font-size: 1.8em;
          //margin-top:-45px;
        }
      }




    }


  }

}


.story-screen-image {
  background-image: url($cdn + '/blacks.jpg');
}



.footer {
  background-color: $coyote;
  width: 100%;
  min-height: 20vh;
  padding: 5vw;

  * {
    font-family: Termina, serif !important;
    font-size: 16px !important;
  }

  &__text {
    color: white;
  }

  &__link {
    color: white !important;
    cursor: pointer;
  }

  &__logo {
    height: 25px;
  }

  &__copyright {
    //font-size: 16px;
    color: white;
    display: block;
    text-align: right;
    padding: 20px 0;
    float: right;

    a {
      color: white;
      text-decoration: none;
    }
  }
  .unegma-blue {
    color: #0288d1;
  }
}

.menu-modal {
  color: white;
}

.solid-border {
  border: 1px solid white !important;
}

.info-modal {
  background-color: black !important;
  border: 1px solid white !important;
}

.events-modal {
  background-color: black !important;
  border: 1px solid white !important;
}

.contact-email {
  color: white;
  font-size: 1.5em !important;

}

.menu-bar-item {
  span {
    font-size: 24px;
    text-align: center;
  }
}

.menu-bar-item-beta {
  opacity: 0.3;
  color: white;
  text-decoration: none;
  span {
    font-size: 24px;
    text-align: center;
  }
}

.membership-menu-button {
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin-top:20px;
}



.generic-page-title {
  color: white;
  position: relative;
  z-index: 99;

  margin-top: 30vh;

  h1 {
    color: $tawny;
    line-height: 0.9em;
    font-size: 3.4em;
  }

  @media screen and (min-width: 768px) {
    position: absolute;
    top: 60vh;
    left: 10vw;
    margin-top: 0vh;

    h1 {
      line-height: 1em;
      font-size: 4em;
    }

  }
}

.story-section {
  padding: 10vw;
  color: white;
  font-size: 1em;

  @media screen and (min-width: 768px) {
    font-size: 2em;
  }
}

.blacks-orange {
  color: $tawny;
}
.sendButton {
  background-color: $tawny !important;
}

.address-info {
  color: white !important;
  font-size: 1.5em !important;
}


.home-section-three {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url($cdn + '/random2_poem.png');

}

.random-queen {
  position: absolute;
  width: 50vw;
  right: 0vw;
  bottom: 0;

  opacity: 0.6;

  @media screen and (min-width: 768px) {
    width: 30vw;
  }

}


.page-numbers {
  margin-left: 20px;
}


.red-input {
  input {
    color: red;
  }
}
.green-input {
  input {
    color: green;
  }
}

.red {
  color: red;
}

.green {
  color: green;
}


.membership-field {
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
  }
}


//.home-screen-cover {
//  display: flex;
//  position: absolute;
//  width:100%;
//  height:100%;
//  background: rgb(2,0,36);
//  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
//  opacity: 0.9;
//  justify-content: center;
//  align-items: center;
//}


.home-page__text-section-one {
  position: absolute;

  top: 33vh;
  @media screen and (min-width: 1080px) {
    top: 20vh;
  }

  left: 10vw;
  z-index: 99;

  p {
    color: black;
  }
}


.section-title {
  font-weight: bold;
  font-size: 10vw;
  line-height:1em;
  color: white !important;
  @media screen and (min-width: 1080px) {
    font-size: 5vw;

  }
}

.section-description {
  font-size: 5vw;
  @media screen and (min-width: 1080px) {
    font-size: 2vw;
    max-width: 50vw;
  }
}

.questionnaire-text-box-select {

  width: 90vw !important;
  @media screen and (min-width: 768px) {
    width: 60vw !important;
  }

  // for select box label
  legend {
    height:20px;
    span {
      //color: white !important;
      font-size:16px;

      opacity: 1;
    }
  }

  * {
    //width: 60vw;
    //text-align: left;

    @media screen and (min-width: 1080px) {

    }
  }
}

.red-input {
  input {
    color: red !important;
  }
}
.green-input {
  input {
    color: green !important;
  }
}

.red {
  color: red !important;
}

.green {
  color: green !important;
}

.questionnaire-text-box {

  width: 90vw !important;
  @media screen and (min-width: 768px) {
    width: 60vw !important;
  }

  label {
    //color: black;
  }
}

.radio-group {
  span {
    text-align: left;
  }

  &--more-padding {
    span {
      margin-bottom: 15px;
    }
  }
}

.starling-button {
  color: $tawny !important;
}

.desktop-hidden {
  @media screen and (min-width: 768px) {
    display: none;
  }
}
